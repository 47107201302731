import React from "react";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>Casablanca, Morocco</p>
            </div>
            <div className="d-flex">
              <a href="tel:+212697332481">+212 6 97 33 24 81</a>
            </div>
            <div className="d-flex">
              <p>lougseco@gmail.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="row">
              <div className="col">
                <a className="footer-nav">Home</a>
                <br />
                <a className="footer-nav">About me</a>
                <br />
                <a className="footer-nav">Services</a>
              </div>
              <div className="col">
                <a className="footer-nav">Experience</a>
                <br />
                <a className="footer-nav">Portfolio</a>
                <br />
                <a className="footer-nav">Contatcs</a>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex justify-content-center">
              <SocialIcon
                className="mx-3"
                url="https://www.linkedin.com/in/saad-eddine-loughzali-3a299115b/"
                target="_blank"
              />
              <SocialIcon
                className="mx-3"
                url="https://www.hackerrank.com/Xreali"
                target="_blank"
              />
              <SocialIcon
                className="mx-3"
                url="https://github.com/LougSE"
                target="_blank"
              />
              <SocialIcon
                className="mx-3"
                url="https://stackoverflow.com/users/10181144"
                target="_blank"
              />
              
              <SocialIcon
                className="mx-3"
                url="https://web.facebook.com/LouuG/"
                target="_blank"
              />
            </div>
            <p className="pt-3 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Lougseco | All Rights Reserved{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
