import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//Avatars imports
import avatar1 from "../images/avatars/CHRISTOPHE DE FIGUEIREDO.png";
import avatar3 from "../images/avatars/avatar-3.png";
import avatar2 from "../images/avatars/Abdelilah Elmanssouri.png";
import avatar4 from "../images/avatars/avatar-4.png";

const TestimonialsCarousel = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={3000}
    >
      <>
        <img src={avatar1} alt="John Doe 1" />
        
        <div className="myCarousel">
          <h3>Christophe De Figueiredo</h3>
          <h4>CEO at Swissport Morocco</h4>
          <p>
            Saad Eddine really annoyed me with his questions Hahaha. Jokes
            aside, he's someone involved in his work. A company needs people
            like Saad Eddine, who will dig for information.
          </p>
        </div>
      </>

      <>
        <img src={avatar2} alt="Jane Doe 2" />
        <div className="myCarousel">
          <h3>Abdelilah El Manssouri</h3>
          <h4>SAP Consultant at Varun Beverages Morocco</h4>
          <p>Saad Eddine is serious and involved in his work. he is autonomous and have the ability to apply the necessary pressure in order to progress.</p>
        </div>
      </>
      {/*
      <>
        <img src={avatar3} alt="John Doe 3" />
        <div className="myCarousel">
          <h3>John Doe 3</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu
            dolor orci. Donec suscipit velit quis mi placerat euismod.
          </p>
        </div>
      </>
*/}

      {/*
      <>
        <img src={avatar4} alt="John Doe 4" />
        <div className="myCarousel">
          <h3>John Doe 4</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu
            dolor orci. Donec suscipit velit quis mi placerat euismod.
          </p>
        </div>
      </>
    */}
    </Carousel>
  );
};

export default TestimonialsCarousel;
