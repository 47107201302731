import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faDesktop, faFileCode,faChartArea, faChartLine, faBroom, faFlask } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <div id="services" className="services">
      <h1 className="py-5">my services </h1>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="box">
            <div className="circle">
              <FontAwesomeIcon className="icon" icon={faChartArea} size="2x" />
            </div>
            <h3>Data Visualisation</h3>
            <p>Transforming complex data into intuitive and engaging visualizations that facilitate easier decision-making and insights</p>
          </div>
        </div>
        {/* - */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="box">
            <div className="circle">
              <FontAwesomeIcon className="icon" icon={faChartLine} size="2x" />
            </div>
            <h3>Data Analysis</h3>
            <p>Utilizing statistical techniques to interpret data, identify trends, and provide actionable insights to drive strategic business outcomes.</p>
          </div>
        </div>
        {/* - */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="box">
            <div className="circle">
              <FontAwesomeIcon className="icon" icon={faBroom} size="2x" />
            </div>
            <h3>Data Cleaning</h3>
            <p>Enhancing data quality by cleaning and preprocessing datasets to ensure accuracy and reliability in analysis</p>
          </div>
        </div>
        {/* - */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="box">
            <div className="circle">
              <FontAwesomeIcon className="icon" icon={faFlask} size="2x" />
            </div>
            <h3>Data Science</h3>
            <p>Applying machine learning algorithms and statistical methods to solve complex problems and predict future trends based on data</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
