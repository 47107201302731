import React from "react";
//react Typed, pour afficher les textes comme si ils sont tapés
import Typed from "react-typed";
//socialIcons
import { SocialIcon } from "react-social-icons";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <div id="home" className="header-wraper">
      <div className="main-info">
        <h1>It engineer</h1>
        <Typed
          className="typed-text"
          strings={[
            "Business Intelligence",
            "Data analytics",
            "Data science",
          ]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />

        <Link
          smooth={true}
          to="contacts"
          offset={-70}
          className="btn-main-offer"
          href="#"
        >
          Contact Me
        </Link>

        <br />

        <div className="socialsHeader">
          <div className="d-flex justify-content-center">
            <SocialIcon
              bgColor="#00b2f9"
              className="mx-3"
              url="https://www.linkedin.com/in/saad-eddine-loughzali-3a299115b/"
              target="_blank"
            />
            <SocialIcon
              bgColor="#00b2f9"
              className="mx-3"
              url="https://www.hackerrank.com/Xreali"
              target="_blank"
            />
            <SocialIcon
              bgColor="#00b2f9"
              className="mx-3"
              url="https://github.com/LougSE"
              target="_blank"
            />
            <SocialIcon
              bgColor="#00b2f9"
              className="mx-3"
              url="https://stackoverflow.com/users/10181144"
              target="_blank"
            />
            <SocialIcon
              bgColor="#00b2f9"
              className="mx-3"
              url="https://web.facebook.com/LouuG/"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
