import React from "react";

const Experience = () => {
  return (
    <div id="experience" className="experience">
      <div className="d-flex justify-content-center my-5">
        <h1>experience</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3> 2018</h3>
            <p>
            <strong>Web developper | AKSAL</strong>
              <br />Developed a .NET application during my internship that dynamically calculated retail workers' commissions based on turnover,
               enhancing payroll accuracy and worker satisfaction.
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3> 2019</h3>
            <p>
            <strong>Data Analyst &amp; Web Developer | Stelia Aerospace</strong>
              <br />
              Designed a .NET application to monitor employee hours, including work,
               break, and overtime, using SQL stored procedures, resulting in improved HR efficiency and time management
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3> 2020</h3>
            <p>
            <strong>Business Intelligence Analyst | Swissport</strong>
              <br />
              Implemented a private <strong>aviation market monitoring</strong> solution, involving Datamart creation with SSIS and ETL processes,
               and developed three PowerBI dashboards ('Market Study', 'Offer Study', 'Profit Forecasting'), 
               achieving deep insights into market dynamics and enabling accurate service profit forecasting using an autoregressive model in Python.

            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3> 2021</h3>
            <p>
            <strong>Data Analyst | Varun Beverages Morocco</strong>
              <br />Improved <strong>MRP process</strong> by automating data calculations for monthly orders through an Excel VBA macro,
               reducing order time from 30 minutes to 10 seconds, significantly increasing operational efficiency.
            </p>
            <p>
            <strong>Business Intelligence Consultant | Netcom Technologies</strong>
              <br />Designed and implemented a dynamic PowerBI reporting solution for <strong>construction project progress</strong>,
               improving stakeholder communication and project transparency.
              Automated data storage with Excel VBA macros and conducted comprehensive ETL and dashboard creation, 
              providing real-time project insights and enhancing productivity metrics.
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2022 - Currently</h3>
            <p>
            <strong>Business Intelligence Analyst | Personal &amp; Freelance Projects</strong>
              <br />I have developed a series of projects, creating dynamic PowerBi dashboards for diverse industries like <strong>education</strong>, <strong>luxury retail</strong>, <strong>healthcare</strong>, <strong>automotive</strong>, and <strong>supermarkets</strong>. Each project involved meticulous data cleaning, crafting insightful KPIs,
                and integrating predictive analytics, enhancing decision-making through detailed visualizations and interactive features.
            </p>
            <p>
            <strong>Data Analyst | Trading</strong>
              <br />Since 2022, I began trading and simultaneously conducting data analysis, leveraging Power BI to analyze over 400 trades, which enhanced my trading strategy and boosted profitability. I developed custom dashboards that helped me reducing losses by 30%.
            </p>
          </div>
        </div>
        {/* */}
      </div>
    </div>
  );
};

export default Experience;
