import React from "react";
import author from "../me.jpg";

const AboutMe = () => {
  return (
    <div id="about" className="container py-5">
      <div className="row">
        <div className="col-lg-6 col-xm-12">
          <div className="photo-wrap mb-5">
            <img className="profile-img" src={author} alt="author..." />
          </div>
        </div>
        <div className="col-lg-6 col-xm-12">
          <h1 className="about-heading">About Me</h1>
          <p className="about-paragraph">
          Hello! I'm Saad Eddine Loughzali, a Data Analyst with an engineering degree in Computer Science from the National School of Computer Science and Systems Analysis, Morocco.
          <br />
          <br />
          Since childhood, I've been drawn to puzzles and brain teasers, a passion that seamlessly transitioned into my professional 
          interest in Business Intelligence, where the thrill of navigating vast data, querying databases efficiently, and crafting insightful reports to reveal trends continually challenges and engages my analytical skill.
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
