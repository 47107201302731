import React from "react";
import netflix from "../images/netflix.png";
import cityGuide from "../images/city-guide-app.png";
import portfolio from "../images/portfolio.png";
import taskManager from "../images/task-manager.png";
import heartattack from "../images/heartattack.jpg";
import waterquality from "../images/waterquality.jpg";
import luxurystore from "../images/luxurystore.jpg";
import supermarket from "../images/supermarket.jpg";
import carstore from "../images/carstore.jpg";

// FONTAWESOME IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
// REACT POPUPBOX
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";

const Pofrfolio = () => {
  // Heart Attack
  const openPopupboxHeartattack = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={heartattack}
          alt="Heart Attack Analysis Project..."
        />

      <section class="project-section">
        <h1>Heart Attack Risk Analysis Dashboard for St. Pedro Hospital</h1>
        <p><strong>Objective:</strong> Develop a predictive and analytical dashboard using Power BI to assess heart attack risks from patient data, improving preventative care measures.</p>
        <p><strong>Tools Used:</strong> Power BI, Python for predictive modeling, Kaggle and Tableau for data visualization.</p>
        <p><strong>Outcomes:</strong></p>
        <ul>
            <li>Enhanced medical staff's ability to identify at-risk patients through interactive data visualizations.</li>
            <li>Supported broader health trends analysis to guide preventative healthcare practices.</li>
        </ul>
        <div>
            | <a href="https://youtu.be/HpI0a0BfKOQ" target="_blank">View Dashboard</a> |
            | <a href="https://www.kaggle.com/abazahada/kaggle-heart-attack-prediction" target="_blank">Kaggle Notebook</a> |
            | <a href="https://public.tableau.com/app/profile/saadeddine.loughzali/viz/HeartAttackProject-Analysis/CasesSummary" target="_blank">Tableau Visualisation</a> |
        </div>
      </section>
      {/*
        <p></p>
        <p>
          Analysis of a public dataset concerning heart attack rates on samples,
          ,building a predictive model with python and creation of dashboard on
          Power Bi.
        </p>
        <b>Power Bi Dashboard:</b>{" "}
        <a
          className="hyper-link"
          onClick={() => window.open("https://youtu.be/HpI0a0BfKOQ")}
        >
          Link
        </a>
        <br />
        <b>Kaggle Notebook:</b>{" "}
        <a
          className="hyper-link"
          onClick={() =>
            window.open(
              "https://www.kaggle.com/abazahada/kaggle-heart-attack-prediction"
            )
          }
        >
          Link
        </a>
        <br />
        <b>Tableau Visualisation:</b>{" "}
        <a
          className="hyper-link"
          onClick={() =>
            window.open(
              "https://public.tableau.com/app/profile/saadeddine.loughzali/viz/HeartAttackProject-Analysis/CasesSummary",
              "_blank"
            )
          }
        >
          Link
        </a>
        {/* */}
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigHeartattack = {
    titleBar: {
      enable: true,
      text: "Data Analysis project.",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  // Water Quality
  const openPopupboxWaterQuality = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={waterquality}
          alt="Water Quality Analysis Project..."
        />

        <section class="project-section">
            <h1>Water Quality Prediction Dashboard</h1>
            <p><strong>Objective:</strong> Predict water quality across various water bodies by analyzing multiple water quality metrics to ensure safety and compliance with health standards.</p>
            <p><strong>Tools Used:</strong> Power BI for data visualization, Python for predictive modeling, and Tableau for detailed interactive visual analytics.</p>
            <p><strong>Outcomes:</strong></p>
            <ul>
                <li>Provided a comprehensive tool for real-time monitoring and prediction of water potability based on WHO standards.</li>
                <li>Enabled data-driven decisions for water treatment processes and public health advisories.</li>
            </ul>
            <div>
                {/* | <a href="https://www.youtube.com/watch?v=HpI0a0BfKOQ&t=8s" target="_blank">View Dashboard</a> |*/}
                | <a href="https://www.kaggle.com/datasets/adityakadiwal/water-potability" target="_blank">Kaggle Dataset</a> |
                | <a href="https://public.tableau.com/views/WaterQualityProject-Analysis/Output?:language=fr-FR&:sid=&:display_count=n&:origin=viz_share_link" target="_blank">Tableau Visualisation</a> |
            </div>
        </section>


        {/*
        <p>
          Analysis of a public dataset concerning the potability of water using
          different features, and building a predictive model with python.
        </p>
        <b>Kaggle Notebook:</b>{" "}
        <a
          className="hyper-link"
          onClick={() =>
            window.open(
              "https://www.kaggle.com/abazahada/kaggle-water-quality-prediction",
              "_blank"
            )
          }
        >
          Link
        </a>
        <br />
        <b>Tableau Visualisation:</b>{" "}
        <a
          className="hyper-link"
          onClick={() =>
            window.open(
              "https://public.tableau.com/app/profile/saadeddine.loughzali/viz/WaterQualityProject-Analysis/Output",
              "_blank"
            )
          }
        >
          Link
        </a>

        {/* */}
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigWaterQuality = {
    titleBar: {
      enable: true,
      text: "Data Analysis project.",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  // Luxury Store
  const openPopupboxLuxurystore = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={luxurystore}
          alt="Luxury Store Sales Dashboard..."
        />


        <section class="project-section">
            <h1>Global Store Performance Dashboard</h1>
            <p><strong>Objective:</strong> Monitor global sales performance, identifying trends across different product lines and deal sizes.</p>
            <p><strong>Tools Used:</strong> Power BI for detailed analytics and geographic data integration.</p>
            <p><strong>Outcomes:</strong></p>
            <ul>
                <li>Provided insights into sales dynamics by region, enhancing targeted marketing strategies.</li>
                <li>Analyzed the impact of deal sizes on profit margins, optimizing product placement and promotions.</li>
            </ul>
            <div>
                | <a href="https://youtu.be/HDojn0ZG-TQ" target="_blank">View Dashboard</a> |
            </div>
        </section>

        {/*
        <p>
          Analysis and dashboard creati for a public dataset about a luxury
          store sales with Power Bi.
        </p>
        <b>Power Bi Dashboard:</b>{" "}
        <a
          className="hyper-link"
          onClick={() => window.open("https://youtu.be/HDojn0ZG-TQ")}
        >
          Link
        </a>
        {/* */}
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigLuxurystore = {
    titleBar: {
      enable: true,
      text: "Data Analysis project.",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  //Cars Store
  const openPopupboxCarstore = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={carstore}
          alt="Cars Store Sales Dashboard..."
        />

        <section class="project-section">
            <h1>Car Sales Dashboard</h1>
            <p><strong>Objective:</strong> Analyze car sales data to improve sales strategies and operational efficiency across various car models and manufacturers.</p>
            <p><strong>Tools Used:</strong> Power BI for real-time sales tracking and data visualization.</p>
            <p><strong>Outcomes:</strong></p>
            <ul>
                <li>Enabled real-time monitoring of sales and profit metrics across different vehicle types and models.</li>
                <li>Facilitated strategic decision-making by highlighting trends in horsepower and fuel efficiency impacts on sales.</li>
            </ul>
            <div>
                | <a href="https://youtu.be/jCSEd8ngjYw" target="_blank">View Dashboard</a> |
            </div>
        </section>
        {/* 
        <p>
          Analysis and dashboard creation for a public dataset about a car store
          sales with Power Bi.
        </p>
        <b>Power Bi Dashboard:</b>{" "}
        <a
          className="hyper-link"
          onClick={() => window.open("https://youtu.be/jCSEd8ngjYw")}
        >
          Link
        </a>
        {/* */}
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigCarstore = {
    titleBar: {
      enable: true,
      text: "Data Analysis project.",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  //SuperMarket
  const openPopupboxSupermarket = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={supermarket}
          alt="Supermarket Store Sales Dashboard..."
        />

        <section class="project-section">
            <h1>Supermarket Global Performance Dashboard</h1>
            <p><strong>Objective:</strong> Track and analyze supermarket sales performance to optimize product offerings and enhance customer segmentation.</p>
            <p><strong>Tools Used:</strong> Power BI for comprehensive data management and interactive reporting.</p>
            <p><strong>Outcomes:</strong></p>
            <ul>
                <li>Streamlined inventory management through detailed sales tracking by product categories.</li>
                <li>Improved customer experience by leveraging sales data to better understand purchase behaviors.</li>
            </ul>
            <div>
                | <a href="https://youtu.be/vndHKA3j8Ic" target="_blank">View Dashboard</a> |
            </div>
        </section>
        {/*
        <p>
          Analysis and dashboard creation for a public dataset about a
          Supermarket store sales with Power Bi.
        </p>
        <b>Power Bi Dashboard:</b>{" "}
        <a
          className="hyper-link"
          onClick={() => window.open("https://youtu.be/vndHKA3j8Ic")}
        >
          Link
        </a>
        {/* */}
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigSupermarket = {
    titleBar: {
      enable: true,
      text: "Data Analysis project.",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  return (
    <div id="portfolio" className="portfolio-wrapper">
      <div className="container">
        <h1 className="text-uppercase text-center py-5">portfolio</h1>

        <div className="image-box-wrapper row justify-content-center">
          <div
            className="portfolio-image-box"
            onClick={openPopupboxHeartattack}
          >
            <img
              className="portfolio-image"
              src={heartattack}
              alt="Heart Attack Analysis Project..."
            />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>

          {/*--Changer en commentaire--*/}

          <div
            className="portfolio-image-box"
            onClick={openPopupboxWaterQuality}
          >
            <img
              className="portfolio-image"
              src={waterquality}
              alt="Water Quality Analysis Project..."
            />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>

          {/*--Changer en commentaire--*/}

          <div
            className="portfolio-image-box"
            onClick={openPopupboxLuxurystore}
          >
            <img
              className="portfolio-image"
              src={luxurystore}
              alt="Luxury Store Sales Dashboard Project..."
            />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>

          {/*--Changer en commentaire--*/}
          <div className="portfolio-image-box" onClick={openPopupboxCarstore}>
            <img
              className="portfolio-image"
              src={carstore}
              alt="Car Store Sales Dashboard Project..."
            />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>

          {/*--Changer en commentaire--*/}
          <div
            className="portfolio-image-box"
            onClick={openPopupboxSupermarket}
          >
            <img
              className="portfolio-image"
              src={supermarket}
              alt="Supermarket Store Sales Dashboard Project..."
            />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
        </div>
      </div>
      <PopupboxContainer {...popupboxConfigHeartattack} />
      <PopupboxContainer {...popupboxConfigWaterQuality} />
      <PopupboxContainer {...popupboxConfigLuxurystore} />
      <PopupboxContainer {...popupboxConfigCarstore} />
      <PopupboxContainer {...popupboxConfigSupermarket} />
    </div>
  );
};

export default Pofrfolio;
